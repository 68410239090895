import { useUserStore } from "@/stores/user";
import type { MiddlewareContext } from "@/types";

export const blockInternal = ({ next, router }: MiddlewareContext) => {
  const store = useUserStore();

  if (store.isInternalAdmin) {
    router.push({ name: "NotFound" });
    return;
  }

  return next();
};
