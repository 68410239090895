<template>
  <footer id="footer" class="footer-container">
    <div id="footer-copyright" class="column is-12-mobile">
      &#169; {{ currentYear }} CareerSafe, LLC. All Rights Reserved.
    </div>
    <div v-if="showLinks" id="footer-links" class="column is-12-mobile">
      <a id="terms-of-use-link" href="https://www.careersafeonline.com/terms-of-use" target="_blank"
        >Terms of Use</a
      >
      <span class="link-divider">|</span>
      <a
        id="privacy-policy-link"
        href="https://www.careersafeonline.com/privacy-policy"
        target="_blank"
        >Privacy Policy</a
      >
      <span class="link-divider">|</span>
      <a id="support-link" href="https://www.careersafeonline.com/support" target="_blank"
        >Support</a
      >
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUserStore } from "@/stores/user";

const store = useUserStore();

const currentYear = computed(() => {
  return new Date().getFullYear();
});

const showLinks = computed(() => {
  return !(store.isEcommerceDisabled && store.isOnlyLearner);
});
</script>

<style scoped lang="scss">
.footer-container {
  align-items: center;
  background-color: $color-background-brand;
  color: $color-text-inverse;
  display: flex;
  flex-direction: column-reverse;
  flex-shrink: 0;
  font-size: $font-12;
  margin-top: auto;
  min-height: $footer-min-height-mobile;
  padding: 0.75rem;
  width: 100%;

  .column {
    padding: 0;
    text-align: center;
  }
  .column:last-child {
    margin: 0 0 0.5rem 0;
  }

  @include tablet {
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    min-height: $footer-min-height;

    .column:last-child {
      margin: 0;
      text-align: right;
    }
    .column:first-child {
      text-align: left;
    }
  }

  .link-divider {
    margin: 0 0.5rem;
  }

  a {
    color: $color-text-inverse;
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &:not(:last-child) {
    padding-bottom: 5rem;
  }
}
</style>
