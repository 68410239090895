import { useAnonymousOrderStore } from "@/stores/anonymousOrder";
import { useUserStore } from "@/stores/user";
import { blockInternal } from "@/middleware/internal";

const ContactSalesView = () => import("./ContactSalesView.vue");
const CheckoutView = () => import("./CheckoutView.vue");
const CheckoutProcessingView = () => import("./CheckoutProcessingView.vue");
const AnonymousCheckoutProcessing = () => import("./AnonymousCheckoutProcessing.vue");

const checkoutRoutes = [
  {
    path: "/checkout/contact-sales",
    name: "ContactSales",
    component: ContactSalesView
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    },
    beforeEnter: () => {
      const anonymousOrderStore = useAnonymousOrderStore();
      anonymousOrderStore.removeAnonymousOrder();
    }
  },
  {
    path: "/checkout/processing",
    name: "CheckoutProcessing",
    component: CheckoutProcessingView,
    props: true,
    meta: {
      requiresAuth: false
    },
    beforeEnter: (to: any) => {
      const anonymousOrderStore = useAnonymousOrderStore();
      const userStore = useUserStore();

      if (anonymousOrderStore.isAnonymous && anonymousOrderStore.order_id === to.query.order) {
        return {
          name: "AnonymousCheckoutProcessing",
          query: { orderId: anonymousOrderStore.order_id, zipCode: anonymousOrderStore.zip_code }
        };
      } else if (!userStore.isAuthenticated) {
        return {
          path: "/signin",
          // Save the location we were at to come back later
          query: { redirect: to.fullPath }
        };
      }
    }
  },
  {
    path: "/pay-now/processing",
    name: "AnonymousCheckoutProcessing",
    component: AnonymousCheckoutProcessing,
    props: true,
    meta: {
      requiresAuth: false
    }
  }
];

export default checkoutRoutes;
