import { blockInternal } from "@/middleware/internal";

const GroupView = () => import("./GroupView.vue");
const GroupSettingsView = () => import("./GroupSettingsView.vue");
const GroupResourcesView = () => import("./GroupResourcesView.vue");
const LicensesView = () => import("./LicensesView.vue");
const GroupSelectionView = () => import("./GroupSelectionView.vue");
const BulkInviteMembersView = () => import("./BulkInviteMembersView.vue");

const groupRoutes = [
  {
    path: "/groups",
    name: "GroupSelectionView",
    component: GroupSelectionView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  },
  {
    path: "/groups/:id",
    name: "Group",
    component: GroupView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  },
  {
    path: "/groups/:id/settings",
    name: "GroupSettings",
    component: GroupSettingsView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  },
  {
    path: "/groups/:id/resources",
    name: "GroupResources",
    component: GroupResourcesView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  },
  {
    path: "/groups/:id/licenses",
    name: "LicensesView",
    component: LicensesView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  },
  {
    path: "/groups/:id/bulk-invite",
    name: "BulkInviteMembersView",
    component: BulkInviteMembersView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  }
];

export default groupRoutes;
