import { blockInternal } from "@/middleware/internal";

const OrganizationSeatsProductsView = () => import("./OrganizationSeatsProductsView.vue");

const organizationRoutes = [
  {
    path: "/organizations/:organizationId/seats-products/:productId?",
    name: "OrganizationSeatsProducts",
    component: OrganizationSeatsProductsView,
    props: true,
    meta: {
      requiresAuth: true,
      middleware: blockInternal
    }
  }
];

export default organizationRoutes;
