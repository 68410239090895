import * as Sentry from "@sentry/vue";
import router from "@/router";
import { version } from "../../package.json";
import type { App } from "vue";

// Documentation: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
export default {
  install: (app: App) => {
    Sentry.init({
      app,

      dsn: import.meta.env.VITE_SENTRY_DSN,

      environment: import.meta.env.VITE_APP_ENV,

      release: "careersafe_frontend@" + version,

      integrations: [Sentry.browserTracingIntegration({ router })],

      // Performance Monitoring
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

      tracePropagationTargets: [/https:\/\/.*.careersafeonline.com.*/g],

      beforeSend(event, hint) {
        const filters = [
          /Couldn't resolve component/gm,
          /Unexpected token '<'/gm,
          /Importing a module script failed./gm
        ];

        const error: any = hint.originalException;

        if (error && error.message) {
          if (filters.find((filter) => error.message.match(filter))) {
            return null;
          }
        }

        return event;
      }
    });
  }
};
